.container {
  width: 100%;

  span {
    font-size: 12px;
    font-weight: bold;
    color: #898989;
    text-transform: capitalize;
    margin: 0 0 8px 8px;

    &.error {
      margin: 10px 0 0;
      color: tomato;
      font-size: 12px;
      text-transform: none;
    }
  }
}
