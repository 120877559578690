.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  border: solid 3px rgba(151, 151, 151, 0.5);
  background-color: #ffffff;
  padding: 14px 22px 14px 14px;
  margin: 25px auto;
  &:last-child {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  span {
    font-size: 12px;
    font-style: normal;
    color: rgba(45, 45, 45, 0.75);
  }

  .contentBox {
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    padding-bottom: 14px ;
    margin-bottom: 10px;
    position: relative;
    > * {
      min-width: 0;
    }
    img {
      max-height: 75px;
      margin: 32px 32px 32px 0;
      object-fit: contain;
      @media only screen and (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .pricingBox {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      span {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #2d2d2d;
        @media only screen and (max-width: 450px) {
          font-size: 15px;
        }
      }
    }
    button {
      position: static;
      min-width: 207px;
    }
  }

  .btnCardMobile {
    display: none;
    width: 100%;
    margin-top: 16px;
    @media only screen and (max-width: 768px) {
      display: flex;
      align-items:center;
    }
  }

  .hide{
    display: none;
  }

}

.compactCards{
  width: 24%;
  height: 120px;
  padding: 16px 16px 16px 11px;
  margin-right: 1%;
  margin-bottom: 15px;
  @media only screen and (max-width: 1200px) {
    width: 32%;
    height: 140px;
  }
  @media only screen and (max-width: 968px) {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
    margin-right: 0;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  .contentBox {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom:transparent;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
    @media only screen and (max-width: 968px) {
      padding-bottom: 14px ;
      margin-bottom: 10px;
      border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    }
    img {
      width: 43px;
      height: 43px;
      @media only screen and (max-width: 968px) {
        width: 57px;
        height: 57px;
      }
    }
    .pricingBox {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      span {
        font-size: 12px;
        &:last-child{
          font-size: 10px;
          font-style: normal;
          color: rgba(45, 45, 45, 0.75);
          margin-top: 5px;
        }
        @media only screen and (max-width: 968px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 450px) {
          font-size: 15px;
        }
      }
    }

  }
  .hide{
    @media only screen and (max-width: 968px) {
      display: none;
    }
  }
  .hideMain{
    display: none;
    @media only screen and (max-width: 968px) {
      display: flex;
    }
  }
}

.active {
  border: solid 3px #f32c32;
}
