.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2d2d2d;
  margin: 20px auto;

  .header {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    @media only screen and (max-width: 450px) {
      display: none;
      font-size: 20px;
      letter-spacing: 1.2px;
    }
  }

  .info {
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 20px;
    font-style: italic;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      letter-spacing: 0.4px;
    }
  }

  .containerCards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containerCardsGallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 968px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .btnWrapper {
      margin-left: 1rem;

      button {
        position: relative;
        top: 5px;
        padding: 20px 50px;

        &:disabled {
          background-color: #e5e5e5;
          color: #2d2d2d;
        }
      }
    }
  }
}
