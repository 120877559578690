.banner {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.homeBanner {
  flex-direction: column;
  align-items: center;
  background-image: url("/static/images/bridgeNight.png");
  background-size: cover;
  color: #ffffff;
  text-transform: capitalize;
  padding: 50px 0;

  @media only screen and (max-width: 968px) {
    height: auto;
    padding: 80px 0 70px;
  }
  @media only screen and (max-width: 768px) {
    padding: 38px 0 32px;
  }

  h3 {
    font-size: 70px;
    font-weight: bold;
    margin: 0px 0 16px;
    text-align: center;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      margin: 0px 0 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 25px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    width: 70%;
    font-style: italic;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 15px;
    }
  }
}

.infoBanner {
  flex-direction: column;
  align-items: center;
  background-image: url("/static/images/bridgeNight.png");
  background-size: cover;
  color: #ffffff;
  text-transform: uppercase;
  padding: 87px 0 152px;
  position: relative;
  @media only screen and (max-width: 450px) {
    padding: 45px 0 100px;
  }

  h3 {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    @media only screen and (max-width: 968px) {
      margin: 0px 0 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 30px;
    }
  }
  span {
    display: none;
  }
}
